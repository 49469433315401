import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumbs, Layout, Hero } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Page = ({ data, pageContext }) => {
  const {
    moduleArea,
    seoMetaTags,
    title,
    heroImage,
    heroImageMobile
  } = data.page

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />

      <Hero heading={title} image={heroImage} mobileImage={heroImageMobile} />
      <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
      <BlockWrapper locale={pageContext.locale} blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      path
      slug
      title
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      heroImageMobile: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "crop", w: "750", q: 60 }
        )
        alt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsAllProjectsList {
          ...AllProjectsList
        }
        ... on DatoCmsBusinessInfoList {
          ...BusinessList
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsContactInfo {
          ...ContactInfo
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsContentCard {
          ...ContentCard
        }
        ... on DatoCmsCta {
          ...Cta
        }
        ... on DatoCmsFilteredBlogList {
          ...FilteredBlogList
        }
        ... on DatoCmsForm {
          ...Form
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageGrid {
          ...ImageGrid
        }
        ... on DatoCmsLatestNews {
          ...LatestNews
        }
        ... on DatoCmsCurrentProjectSlider {
          ...CurrentProjectSlider
        }
        ... on DatoCmsPartnersSlider {
          ...PartnersSlider
        }
        ... on DatoCmsSlider {
          ...Slider
        }
        ... on DatoCmsVideo {
          ...Video
        }
      }
    }
  }
`
export default Page
